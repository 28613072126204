import React from 'react'
import styled from 'styled-components'
import { media } from 'src/styles/util'
import { colors } from 'src/styles/variables'

import { LargeP } from 'src/components/shared/text'
import DropHeader from 'src/components/shared/DropHeader'
import ParisFinnDesktop from 'src/assets/img/paris-hilton/paris-finn-bottles-desktop.png'
import ParisFinnMobile from 'src/assets/img/paris-hilton/paris-finn-bottles-mobile.png'

const Container = styled.div`
  padding: 10px 20px 72px;
  width: 100%;
  background-color: ${colors.pink};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  ${media.tablet`
    padding-top: 40px;
  `}
`

const ParisImageDesktopTablet = styled.img`
  display: none;
  ${media.tablet`
    display: block;
    margin-top: -70px;
    max-width: 1300px;
    width: 100%;
  `}
  ${media.desktop`
    margin-top: -130px;
  `}
`

const ParisImageMobile = styled.img`
  margin-top: -40px;
  width: 100%;
  ${media.tablet`
    display: none;
  `}
`

const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 16px 0;
  ${media.tablet`
    padding: 0;
    max-width: 650px;
  `}
  ${media.desktop`
    padding: 0;
    max-width: 920px;
  `}
`

const FinnAndParis = () => {
  return (
    <Container>
      <DropHeader title="Finn + Paris" titleMobile={`Finn\n+ Paris`} />
      <ParisImageDesktopTablet
        src={ParisFinnDesktop}
        alt="Paris Hilton holding up two containers of Finn dog products"
      />
      <ParisImageMobile
        src={ParisFinnMobile}
        alt="Paris Hilton holding up two containers of Finn dog products"
      />
      <TextContainer>
        <LargeP>
          It didn’t take long after meeting Paris Hilton to discover we share the same perspective —
          the unconditional love we get from our dogs is like nothing else on Earth.
          <br />
          <br />
          And sometimes it feels like there’s no way we could ever do enough to give that love back.
          So we’ve both set out to make the world a happier place through our shared love of pups.
          For Paris, it’s through relentless advocacy for animal rights and generous support of
          animal charities and shelters. At Finn, we developed a human-grade approach to dog
          nutrition, with wellness routines to keep our best friends in their best condition for the
          longest time possible.
          <br />
          <br />
          We couldn’t be more thrilled to be on this mission together.
        </LargeP>
      </TextContainer>
    </Container>
  )
}

export default FinnAndParis
