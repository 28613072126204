import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql, navigate, Link } from 'gatsby'
import { useRecoilState } from 'recoil'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'

import { H4, H6, P, LargeP, Headline, SmallP } from '../shared/text'
import { PrimaryButton } from '../shared/buttons'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'

import { productDetailState } from '../../actions/products'

import DropHeader from '../../components/shared/DropHeader'

import { trackProductClickEvent } from '../../utils/event-tracking'

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.1); }
  70% { transform: rotate(-2deg) scale(1.1); }
  100% { transform: rotate(0deg); }
`

const Section = styled.div`
  width: 100%;
  position: relative;

  ${media.mobile`
    padding: 0 0 40px 0;
  `}
  ${media.tablet`
    padding: 40px 40px 60px 40px;
  `}
  ${media.desktop`
    padding: 0 0 80px 0;
  `}

  h1 {
    color: ${colors.navy};
    ${media.desktop`
      margin-bottom: 60px;
    `}
  }
`

const Grid = styled.div<{ isProductLibrary: boolean }>`
  width: 100%;

  justify-content: center;

  margin: 0 auto;

  ${media.mobile`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 0;
  `}
  ${media.tablet`
    // display: flex;
    // flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px 30px;

    max-width: 750px;
    padding-top: 60px;
  `}
  ${media.desktop`
    gap: 100px 85px;
    padding: 0;
    grid-template-columns: repeat(3, 1fr);
  `}
`

const CircularBadge = styled.div`
  background-color: ${colors.orange};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  z-index: 1;
  position: absolute;
  top: 15px;
  right: -12px;
  color: ${colors.navy} !important;
  font-family: ${fonts.larsseit.medium};
  font-size: 14px;
  line-height: 16px;
`

const OutOfStockBadge = styled(CircularBadge)`
  background-color: ${colors.teal};
  top: -26px;
  right: 0;
`

const ProductGroup = styled(Link)<{ noBorder: boolean }>`
  display: grid;
  place-items: center;
  grid-row-gap: 12px;
  color: ${colors.navy};

  text-align: center;
  margin: 0 auto;

  & img {
    transition: 200ms all ease;
  }

  &:hover {
    img {
      animation: ${wiggle} 250ms;
    }
    button {
      transform: scale(1.07);
    }
  }

  img,
  button {
    pointer-events: none;
  }

  ${media.mobile`
    padding: 24px 12px 32px 12px;
    height: 100%;
  `}
  ${media.tablet`
    padding: 0;
    border: none;
    height: fit-content;
  `}
  ${media.desktop`
    grid-gap: 15px;
  `}
`

const ProductName = styled(Headline)`
  //white-space: nowrap;

  ${media.mobile`
    font-size: 18px !important;
  `}
  ${media.desktop`
    font-size: 30px !important;
  `}
`

const PriceGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`

const PriceText = styled(LargeP)`
  line-height: 1 !important;
  color: ${colors.orange};

  ${media.mobile`
    font-size: 18px !important;
  `}
  ${media.desktop`
    font-size: 30px !important;
    margin-top: -6px;
  `}
`

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: auto;
  position: relative;
  background-color: #f9f9f9;

  ${media.mobile`
    width: 100%;
    padding: 20px 10px;
    border-radius: 10px;
  `}
  ${media.tablet`
    width: 100%;
  `}
  ${media.desktop`
    width: 300px;
    min-height: 300px;
    padding: 20px;
    border-radius: 20px;
  `}

  &:hover {
    animation: ${wiggle} 250ms;
  }
`

const Description = styled(SmallP)`
  color: ${colors.slate};
  line-height: 1.4 !important;
  text-align: center;
  max-width: 275px;

  ${media.mobile`
    font-size: 10px;
  `}
  ${media.tablet`
    display: block;
    font-size: 14px;
  `}
  ${media.desktop`
    font-size: 15px;
  `}
`

const StruckText = styled(LargeP)`
  color: ${colors.slate};
  text-decoration: line-through;
  margin-left: 12px;
  padding-bottom: 4px;
  line-height: 1 !important;
`

const Title = styled(H4)`
  text-align: center;
  margin-top: 42px;
  ${media.tablet`
    margin-top: 20px;
  `}
  ${media.desktop`
    margin-top: 0;
  `}
`

const PointerWrapper = styled.div`
  width: 100%;
  cursor: pointer;

  &:hover {
    color: ${colors.orange};
  }
`

const query = graphql`
  query {
    chillPup: file(relativePath: { regex: "/tins/chill-pup-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    activePup: file(relativePath: { regex: "/tins/active-pup-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    prettyPup: file(relativePath: { regex: "/tins/pretty-pup-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    starter: file(relativePath: { regex: "/tins/starter-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    comfy: file(relativePath: { regex: "/tins/comfy-pup-bundle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const BuyABundle = ({
  isProductLibrary,
  title,
  noBorder,
  showBadge,
  isAlternate,
  isHomePage
}: {
  isProductLibrary?: boolean
  title?: string
  noBorder?: boolean
  showBadge?: boolean
  isAlternate?: boolean
  isHomePage?: boolean
}) => {
  const images = useStaticQuery(query)
  const [productMap] = useRecoilState(productDetailState)
  const slugMap = productMap?.bySlug

  useEffect(() => {
    if (slugMap) {
      const from = { opacity: 0, y: 50 }
      const to = { ease: Power3.easeOut, opacity: 1, duration: 1, y: 0 }
      gsap.fromTo('.Products_BuyABundle_TheChillPup', from, { ...to, delay: 0.25 })
      gsap.fromTo('.Products_BuyABundle_ThePrettyPup', from, { ...to, delay: 0.3 })
      gsap.fromTo('.Products_BuyABundle_TheActivePup', from, { ...to, delay: 0.35 })
      gsap.fromTo('.Products_BuyABundle_StarterBundle', from, { ...to, delay: 0.4 })
      gsap.fromTo('.Products_BuyABundle_ComfyBundle', from, { ...to, delay: 0.45 })
    }
  }, [slugMap])

  const chillPup = {
    name: 'Chill Pup',
    mobileName: 'Chill Pup',
    img: getImage(images.chillPup),
    slug: 'chill-pup-bundle',
    class: 'Products_BuyABundle_TheChillPup',
    description:
      'Support your pup through stressful moments with Calming Aid + daily Multivitamin / Probiotic',
    badge: 'Most Popular'
  }

  const products = [
    {
      name: 'Pretty Pup',
      mobileName: 'Pretty Pup',
      img: getImage(images.prettyPup),
      slug: 'pretty-pup-bundle',
      class: 'Products_BuyABundle_ThePrettyPup',
      description:
        'Treat your pooch to a luxurious coat with daily Skin & Coat + Multivitamin / Probiotic'
    },
    {
      name: 'Active Pup',
      mobileName: 'Active Pup',
      img: getImage(images.activePup),
      slug: 'active-pup-bundle',
      class: 'Products_BuyABundle_TheActivePup',
      description:
        'Nurture joint mobility and tissue repair with daily Hip & Joint + Multivitamin / Probiotic'
    },
    {
      name: 'Starter Bundle',
      mobileName: 'Starter Bundle',
      img: getImage(images.starter),
      slug: 'starter-bundle',
      class: 'Products_BuyABundle_StarterBundle',
      description:
        'For the dog that deserves it all – Calming Aid, Digestive Probiotics + daily Multivitamin. '
    },
    {
      name: 'Comfy Pup Bundle',
      mobileName: 'Comfy Pup Bundle',
      img: getImage(images.comfy),
      slug: 'comfy-pup-bundle',
      class: 'Products_BuyABundle_ComfyBundle',
      description:
        'For the dog that deserves it all – Calming Aid, Digestive Probiotics + daily Multivitamin. '
    }
  ]

  let displayedProducts = products

  if (!isAlternate) displayedProducts = [chillPup, ...products]

  const { fetching } = productMap

  const trackProductClick = (slug: string) => {
    if (!fetching) {
      trackProductClickEvent(productMap, slug)
    }
  }

  return (
    <Section>
      {Boolean(title) && (
        <DropHeader
          id="bundle-tins"
          title={title}
          fontSize={133}
          tabletFontSize={88}
          mobileFontSize={50}
        />
      )}
      <Grid isProductLibrary={isProductLibrary}>
        {slugMap &&
          displayedProducts.map((product) => {
            const productDetail = slugMap[product.slug]
            const OTPPrice = productDetail?.single?.pricing?.oneTime?.price || 0
            const variantId = productDetail?.single?.variantId
            const showCTA = variantId && isProductLibrary

            return (
              <ProductGroup
                noBorder={noBorder}
                className={product.class}
                key={product.name}
                to={`/products/${product.slug}`}
                onClick={() => trackProductClick(product.slug)}
              >
                <PointerWrapper>
                  <ImgWrapper>
                    {Boolean(showBadge) && Boolean(product.badge) && (
                      <CircularBadge>{product.badge}</CircularBadge>
                    )}
                    {Boolean(product.outOfStock) && (
                      <OutOfStockBadge>
                        Sold <br />
                        Out
                      </OutOfStockBadge>
                    )}
                    <GatsbyImage
                      image={product.img}
                      alt={`${product.name} product bundle`}
                      style={{ height: '100%', width: '100%' }}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </ImgWrapper>
                </PointerWrapper>
                <PointerWrapper>
                  <ProductName as="p">{product.name}</ProductName>
                </PointerWrapper>

                {!!OTPPrice && (
                  <PriceGrid>
                    <PriceText>${OTPPrice.toFixed(2)}</PriceText>
                  </PriceGrid>
                )}

                <Description>{product.description}</Description>

                {showCTA && (
                  <>
                    {product.outOfStock ? (
                      <PrimaryButton copy="Out of Stock" bg={colors.mediumGrey} />
                    ) : (
                      <PrimaryButton copy="Shop Now" arrow />
                    )}
                  </>
                )}
              </ProductGroup>
            )
          })}
      </Grid>
    </Section>
  )
}

export default BuyABundle
