import React from 'react'
import styled from 'styled-components'

import DesktopBanner from 'src/assets/img/paris-hilton/paris-hilton-banner-desktop.png'
import MobileBanner from 'src/assets/img/paris-hilton/paris-hilton-banner-mobile.png'
import { media } from 'src/styles/util'
import { colors, fonts } from 'src/styles/variables'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.white};
  ${media.mobile`
    margin: 65px 0 40px 0;
    min-height: 500px;
    height: fit-content;
  `}
  ${media.tablet`
    min-height: 400px;
    height: 60vh;
    margin: 75px 0 40px 0;
  `}
  ${media.desktop`
    min-height: 600px;
    height: 83vh;
    margin: 75px 0 75px 0;
  `}
`

const Content = styled.div`
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: 500px;
  width: 100%;

  ${media.mobile`
    background-image: url(${(props) => props.mobileImage});
    text-align: center;
    background-position: center top;
  `}
  ${media.tablet`
    background-image: url(${(props) => props.desktopImage});
    background-position: 25% 50%;
    text-align: left;
  `}
`

const InternalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  ${media.mobile`
    grid-template-columns: 1fr 1fr;
    place-items: center;
    padding: 62px 18px 0;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
    align-items: start;
    justify-items: start;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    max-width: 1400px;
    margin: 0 auto;
    align-items: center;
  `}
`

const QuoteText = styled.h1`
  color: ${colors.lightText};
  font-family: ${fonts.athletics.medium};
  font-size: 22px;
  line-height: 30px;
  padding-bottom: 27px;
  text-align: right;
  ${media.tablet`
    text-align: left;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 22px;
  `}
  ${media.desktop`
    font-size: 46px;
    line-height: 56px;
    padding-bottom: 22px;
  `}
`

const QuoteName = styled.h3`
  color: ${colors.lightText};
  font-family: ${fonts.monosten.normal};
  font-size: 14px;
  text-align: right;
  ${media.tablet`
    font-size: 16px;
    text-align: left;
  `}
`

const TextContainer = styled.div`
  width: 100%;
  grid-column-start: 2;
  ${media.mobile`
    max-width 360px;
  `}
  ${media.tablet`
    max-width 475px;
    padding: 50px 20px 0;
  `}
  ${media.desktop`
    max-width 650px;
    padding: 0 20px 40px;
  `}
`

const AboveTheFold = () => {
  return (
    <Section>
      <Content desktopImage={DesktopBanner} mobileImage={MobileBanner}>
        <InternalWrapper>
          <TextContainer>
            <QuoteText>
              “My babies think they're getting a treat, but I know they're getting the nutrition
              they deserve”
            </QuoteText>
            <QuoteName>— PARIS HILTON</QuoteName>
          </TextContainer>
        </InternalWrapper>
      </Content>
    </Section>
  )
}

export default AboveTheFold
