import React from 'react'
import styled from 'styled-components'
import { H4 } from 'src/components/shared/text'
import { fonts, colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

const Container = styled.div`
  padding: 40px 20px 72px;
  width: 100%;
  margin: 0 auto;
  ${media.tablet`
    max-width: 1060px;
    padding: 0 34px 87px;
  `}
`

const QuoteText = styled(H4)`
  text-align: center;
  margin-bottom: 34px;
`

const QuoteName = styled.h5`
  color: ${colors.navy};
  font-family: ${fonts.monosten.normal};
  font-size: 16px;
  text-align: center;
`

const BannerQuote = () => (
  <Container>
    <QuoteText>“There’s nothing I wouldn’t do for my pups to give them a long and healthy life.”</QuoteText>
    <QuoteName>— PARIS HILTON</QuoteName>
  </Container>
)

export default BannerQuote
