import React from 'react'
import styled from 'styled-components'

import BuyABundle from '../BuyABundle'
import AboveTheFold from './AboveTheFold'
import BannerQuote from './BannerQuote'
import FinnAndParis from './FinnAndParis'

const Container = styled.div`
  background-color: #fff;
  width: 100%;
`

const Products = () => {
  return (
    <Container>
      <AboveTheFold />
      <BuyABundle isProductLibrary title="Paris’ Picks" noBorder showBadge />
      <BannerQuote />
      <FinnAndParis />
    </Container>
  )
}

export default Products
