import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const DEFAULT_KEYWORDS = [
  'Finn',
  'Finn Supplements',
  'Finn Pet Supplements',
  'Finn Soft Chews',
  'Finn Dog Supplements',
  'Finn Wellness',
  'Finn Dog Vitamins',
  'Pet Supplements',
  'Dog Vitamins'
]

interface SEOProps {
  title?: string
  description?: string
  keywords?: string[]
  image?: string
  location: any
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        ogImage
      }
    }
  }
`

const SEO: React.FC<SEOProps> = (props) => {
  const { description = '', keywords = [], title = '', image, location } = props
  const combinedKeywords = DEFAULT_KEYWORDS.concat(keywords)

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description || data.site.siteMetadata.description
        const ogImg = image || data.site.siteMetadata.ogImage
        return (
          <Helmet
            htmlAttributes={{
              lang: 'en'
            }}
            title={title}
            titleTemplate="%s"
            defaultTitle={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content: metaDescription
              },
              {
                name: 'keywords',
                content: combinedKeywords.join(`, `)
              },
              {
                property: 'og:title',
                content: title
              },
              {
                property: 'og:url',
                content: location.href
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                property: 'og:image',
                content: ogImg
              },
              {
                property: 'og:locale',
                content: 'en_US'
              },
              {
                name: `twitter:card`,
                content: `summary`
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author
              },
              {
                name: `twitter:title`,
                content: `${title}`
              },
              {
                name: `twitter:description`,
                content: metaDescription
              }
            ]}
          />
        )
      }}
    />
  )
}

export default SEO
