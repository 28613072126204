import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import ParisHiltonCollectionPage from 'src/components/Products/ParisHilton'
import SEO from 'src/components/shared/SEO'

const ProductsPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Paris Hilton" location={location} />

    <Layout location={location}>
      <ParisHiltonCollectionPage />
    </Layout>
  </>
)

export default ProductsPage
